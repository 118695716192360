import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Table } from 'react-bootstrap';
import cronstrue from 'cronstrue';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const CronJobFinder = () => {
  const [cronExpression, setCronExpression] = useState('');
  const [parsedCron, setParsedCron] = useState('');

  const handleCronChange = (e) => {
    setCronExpression(e.target.value);
  };

  const handleParseCron = () => {
    try {
      const humanReadable = cronstrue.toString(cronExpression);
      setParsedCron(humanReadable);
    } catch (err) {
      setParsedCron('Invalid cron expression');
    }
  };

  return (
    <div className="app-container">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={8}>
          <div>
            <h3 className="text-center h3-header" style={{ fontSize: '2rem' }}>
              Cron expression generator by <span style={{ color: 'lightblue', textDecoration: 'underline' }}>K8Ops</span>.
            </h3>
          </div>
            
            {/* Card for Cron Job Expression Finder */}
            <Card className="custom-card custom-card-one-bottom shadow-lg">
              <Card.Header>
                <div className="header-background">
                  <h3 className="text-center" style={{ fontSize: '1.2rem' }}>Cron Job Expression Finder</h3>
                </div>
              </Card.Header>
            </Card>

            {/* Enter Cron Expression */}
            <Card className="custom-card custom-card-two-bottom shadow-lg">
              <Card.Body>
                <Form>
                  <Form.Group controlId="cronInput" className="mb-3">
                    <Row>
                      <Col>
                        <Form.Label className="text-secondary" style={{ fontSize: '0.9rem', }}>Enter Cron Expression</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="*/5 * * * *"
                          value={cronExpression}
                          onChange={handleCronChange}
                          style={{ fontSize: '1rem' }}
                        />
                      </Col>
                      <Col md={4} className="align-self-end">
                        <Button
                          variant="primary"
                          onClick={handleParseCron}
                          style={{ width: '100%', fontSize: '0.7rem' }} // Full-width button with reduced font size
                        >
                          Parse Cron
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>

                {/* Enhanced Human-Readable Format Output */}
                <div className="mt-4">
                  <h5 className="text-secondary" style={{ fontSize: '0.9rem' }}>Human-Readable Format:</h5>
                  <div className="parsed-cron-output">
                    <p className='paragraph-cron-out' style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{parsedCron}</p> {/* Larger font for parsed result */}
                  </div>
                </div>
              </Card.Body>
            </Card>

            {/* Cron Expression Fields */}
            <Card className="custom-card custom-card-three-bottom shadow-lg">
              <Card.Body>
                {/* Cron Expression Fields Table */}
                <div className="mt-4">
                  <p className="text-secondary" style={{ fontSize: '0.9rem' }}>The cron expression is made of five fields. Each field can have the following values.</p>
                  <Table bordered className="text-center" style={{ fontSize: '0.7rem' }}>
                    <tbody>
                      <tr className="t-header">
                        <td>*</td>
                        <td>*</td>
                        <td>*</td>
                        <td>*</td>
                        <td>*</td>
                      </tr>
                      <tr>
                        <td>minute (0-59)</td>
                        <td>hour (0-23)</td>
                        <td>day of the month (1-31)</td>
                        <td>month (1-12)</td>
                        <td>day of the week (0-6)</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                {/* Cron Expression Reference Table */}
                <div className="mt-4">
                  <p className="text-secondary" style={{ fontSize: '0.9rem', padding: '5px', borderRadius: '5px' }}>Here are some examples for you.</p> {/* Add background color to this text */}
                  <Table striped bordered hover style={{ fontSize: '0.7rem', textAlign: 'center' }}>
                    <thead>
                      <tr style={{ backgroundColor: '#f2f2f2' }}> {/* Add background color to the first row */}
                        <th style={{ textAlign: 'center' }}>Cron Expression</th>
                        <th style={{ textAlign: 'center' }}>Schedule</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>* * * * *</td>
                        <td style={{ textAlign: 'center' }}>Every minute</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>*/10 * * * *</td>
                        <td style={{ textAlign: 'center' }}>Every 10 minutes</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>0 * * * *</td>
                        <td style={{ textAlign: 'center' }}>Every hour</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>0 0 * * *</td>
                        <td style={{ textAlign: 'center' }}>Every day at 12:00 AM</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>0 0 * * FRI</td>
                        <td style={{ textAlign: 'center' }}>At 12:00 AM, only on Friday</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>0 0 1 * *</td>
                        <td style={{ textAlign: 'center' }}>At 12:00 AM, on day 1 of the month</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'center' }}>0 0 1 1 *</td>
                        <td style={{ textAlign: 'center' }}>At 12:00 AM, on January 1st (Every year)</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

  );
};

export default CronJobFinder;
